import React from "react";

const ModalPackage = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5863 15.2385V6.76144C18.5863 6.63946 18.555 6.51966 18.4955 6.41436C18.436 6.30906 18.3506 6.22204 18.2479 6.16224L10.9462 1.90833C10.8469 1.85045 10.7348 1.82004 10.6208 1.82004C10.5068 1.82004 10.3947 1.85045 10.2954 1.90833L2.99363 6.16224C2.89098 6.22204 2.80554 6.30906 2.74607 6.41436C2.68661 6.51966 2.65527 6.63946 2.65527 6.76144V15.2385C2.65527 15.3605 2.68661 15.4803 2.74607 15.5856C2.80554 15.6909 2.89098 15.7779 2.99363 15.8377L10.2954 20.0916C10.3947 20.1495 10.5068 20.1799 10.6208 20.1799C10.7348 20.1799 10.8469 20.1495 10.9462 20.0916L18.2479 15.8377C18.3506 15.7779 18.436 15.6909 18.4955 15.5856C18.555 15.4803 18.5863 15.3605 18.5863 15.2385Z" stroke="#413A44" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.688 13.1064V8.63766L6.6377 4.03906" stroke="#413A44" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.4947 6.41324L10.6994 11L2.74707 6.41238" stroke="#413A44" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.6995 11L10.6216 20.18" stroke="#413A44" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
     </svg>

  );
};

export { ModalPackage };
