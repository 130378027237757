import productosIcon from "shared/images/productos.svg";
import { Button, Input } from "UI/atoms/index";
import { Spinner } from "shared/assets/svgs";
import { TableHeaders } from "./UI/molecules/TableHeaders";
import { ReportModal } from "./UI/organisms/ReportModal";
import { advicesBuilder } from "utils/advicesBuilder";
import { NoResultsAdvice } from "UI/atoms/NoResultsAdvice";
import { useGetData } from "shared/queries/commons";
import { useGetLookups } from "shared/queries/lookups";
import { ReportRow } from "./UI/molecules/ReportRow";
import { useState } from "react";
import { DownloadCloud } from "shared/assets/svgs/DownloadCloud";
import { useDownloadReportsPdf, useDownloadReportsExcel } from "../queries/reports";
import { useGetReports } from "../queries/reports";
import { SelectWithlabel } from "./UI/atoms/SelectWithLabel";
import { saveAs } from "file-saver";
import useSortHandler from "utils/useSortHandler";
import { InputWithDropdown } from "./UI/atoms/InputWithDropdown";
import useDebounce from "shared/queries/useDebounce";
import { useGetArtisansData } from "pages/Artisans/queries/artisans";

const currentDate = new Date();
const yesterday = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);

const ReportsList = () => {
  const [fromDay, setFromDay] = useState(
    yesterday.toISOString().substring(0, 10)
  );
  const [toDay, setToDay] = useState(
    currentDate.toISOString().substring(0, 10)
  );
  const [association, setAssociation] = useState();
  const [workGroup, setWorkGroup] = useState();
  const [warehouse, setWarehouse] = useState();
  const [artisan, setArtisan] = useState('');
  const [report, setReport] = useState({});
  const [isOpenReportModal, setIsOpenReportModal] = useState(false);
  const [isAllowedToDownloadPdf, setIsAllowedToDownloadPdf] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const debouncedSearchArtisan = useDebounce(inputValue, 300);
  const debouncedFromDay = useDebounce(fromDay, 300);
  const debouncedToDay = useDebounce(toDay, 300);

  const { data: warehouses, isLoading: isWarehousesLoading } = useGetData(
    "warehouses",
    "warehouses"
  );

const { data: artisans, isLoading: isArtisansLoading } = useGetArtisansData({
  name: debouncedSearchArtisan,
  pageNumber: 1,
  enabled: !!debouncedSearchArtisan
});

  const { data: lookups, isLoading: isLoadingLookups } = useGetLookups();
  const { data: reports, isLoading: isReportsLoading } = useGetReports({
    association,
    workGroup,
    warehouse,
    fromDay: debouncedFromDay,
    toDay: debouncedToDay,
    artisan
  });
  const { data: pdfData, refetch } = useDownloadReportsPdf({
    association,
    workGroup,
    warehouse,
    fromDay: debouncedFromDay,
    toDay: debouncedToDay,
    artisan,
  });
  const { data: excelData, refetch: refetchExcel } = useDownloadReportsExcel({
    association,
    workGroup,
    warehouse,
    fromDay: debouncedFromDay,
    toDay: debouncedToDay,
    artisan,
  });
  const { work_groups, associations } = lookups;

  const results = advicesBuilder(reports);

  const downloadPdf = async () => {
    const response = await refetch();
    const file = new Blob([response.data], { type: "application/pdf" });
    saveAs(file, "listado.pdf");
  };

  const downloadExcel = async () => {
    const response = await refetchExcel();
    const file = new Blob([response.data], { type: "application/vnd.ms-excel" });
    saveAs(file, "listado.xlsx");
  };

  const handleFromDayBlur = () => {
    if (fromDay && toDay) {
      const from_day = new Date(fromDay);
      const to_day = new Date(toDay);

      if (to_day < from_day) {
        return;
      }
    }
  };

  const handleToDayBlur = () => {
    if (fromDay && toDay) {
      const from_day = new Date(fromDay);
      const to_day = new Date(toDay);
      if (to_day < from_day) {
        return;
      }
    }
  };

  const extractUserAndArtisanDataToSort = (reports) => {
    return reports.map(report => ({
      ...report,
      user: report.user, 
      artisan: report.from_artisan_production_order_item?.artisan
    }));
  };

  const reportsWithUserAndArtisanData = extractUserAndArtisanDataToSort(reports)
  
  const { handleSortBy, sortedData } = useSortHandler(reportsWithUserAndArtisanData);

  if (isLoadingLookups) return <Spinner />;

  const mappedArtisans = artisans?.results?.map((ar) => ({
    ...ar, 
    id: ar.user,
    name: `${ar.first_name} ${ar.last_name}` 
  }));
  
  return (
    <>
      <div className="productos-page-topbar">
        <div className="ptitle">
          <h2>
            <img src={productosIcon} alt="Productos" />
            Reportes / Entrega de mercadería
          </h2>
        </div>
        <ul>
          <li style={{ display: "flex" }}>
            <Button
              background="transparent"
              color="#339589"
              width="180px"
              height="44px"
              fontSize="1.5em"
              className="action-btn"
              onClick={() => downloadPdf()}
              style={{ marginRight: "10px" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginRight: "5px" }}>
                  <DownloadCloud />
                </div>{" "}
                Descargar PDF
              </div>
            </Button>
            <Button
              background="transparent"
              color="#339589"
              width="180px"
              height="44px"
              fontSize="1.5em"
              className="action-btn"
              onClick={() => downloadExcel()}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginRight: "5px" }}>
                  <DownloadCloud />
                </div>{" "}
                Descargar Excel
              </div>
            </Button>
          </li>
        </ul>
      </div>

      <div className="productos-page-categorias">
        <ul style={{ display: "block" }}>
          <div style={{ display: "flex", marginBottom: "20px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "20px",
              }}
            >
              <label htmlFor="max_delivery_date">Desde:</label>
              <Input
                type="date"
                placeholder="from_day"
                onChange={(e) => setFromDay(e.target.value)}
                onBlur={handleFromDayBlur}
                value={fromDay}
                height="40px"
                fontSize="1.4em"
                border="none"
              />
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <label htmlFor="max_delivery_date">Hasta:</label>
              <Input
                type="date"
                placeholder="to_day"
                onChange={(e) => setToDay(e.target.value)}
                onBlur={handleToDayBlur}
                value={toDay}
                height="40px"
                fontSize="1.4em"
                border="none"
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <SelectWithlabel
              label="Depósito"
              isLoading={false}
              name="warehouse"
              onSetterFn={setWarehouse}
              items={warehouses}
              defaultOption={"Todos"}
            />
            <SelectWithlabel
              label="Asociación"
              isLoading={false}
              name="associations"
              onSetterFn={setAssociation}
              items={associations}
            />
            <SelectWithlabel
              label="Grupo de trabajo"
              isLoading={false}
              name="work_groups"
              onSetterFn={setWorkGroup}
              items={work_groups}
              defaultOption={"Todos"}
            />
            <InputWithDropdown
              label="Artesana"
              isLoading={false}
              items={mappedArtisans}
              onSetOptionSelected={setArtisan}
              inputValue={inputValue}
              onSetInputValue={setInputValue}
            />
          </div>
        </ul>
      </div>
      <div className="productos-table-container reports">
        {isReportsLoading ? (
          <Spinner />
        ) : (
          <table className="productos-table-1">
            
            {!isReportsLoading && !results.result ? (
              <span style={{padding: '20px'}}><NoResultsAdvice message={results.message} /></span>
            ) : <TableHeaders onHandleSortBy={handleSortBy} />}
            <tbody>
              {isReportsLoading ? (
                <tr>
                  <th>
                    <Spinner />
                  </th>
                </tr>
              ) : (
                sortedData.map((report) => (
                  <ReportRow
                    key={report.id}
                    report={report}
                    onSetReport={setReport}
                    onSetIsOpenReportModal={setIsOpenReportModal}
                  />
                ))
              )}
            </tbody>
          </table>
        )}
      </div>

      {isOpenReportModal ? (
        <ReportModal
          report={report}
          onSetIsOpenReportModal={setIsOpenReportModal}
          isOpenReportModal={isOpenReportModal}
        />
      ) : null}
    </>
  );
};

export default ReportsList;
