import { useQuery } from "@tanstack/react-query";
import { client } from "utils/api-client";
import { saveAs } from "file-saver";

const errors = [401, 403, 404, 500];

const useGetReports = (filters = {}) => {
  const { warehouse, association, workGroup, fromDay, toDay, artisan } = filters;
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: ["reports", filters],
      queryFn: () =>
        client("admin/reports/production", {
          params: {
            to_warehouse: warehouse,
            association,
            work_group: workGroup,
            from_day: fromDay,
            to_day: toDay,
            artisan: artisan
          },
        }).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

const useDownloadReportsPdf = (filters = {}) => {
  const { warehouse, association, workGroup, fromDay, toDay, artisan } = filters;
  const { data, isLoading, isError, isSuccess, refetch } = useQuery(
    {
      queryKey: ["pdf-reports"],
      queryFn: () =>
        client("admin/reports/production/pdf/", {
          responseType: "arraybuffer",
          params: {
            to_warehouse: warehouse,
            association,
            work_group: workGroup,
            from_day: fromDay,
            to_day: toDay,
            artisan: artisan,
          },
        }).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};

const useDownloadReportsExcel = (filters = {}) => {
  const { warehouse, association, workGroup, fromDay, toDay, artisan } = filters;
  const { data, isLoading, isError, isSuccess, refetch } = useQuery(
    {
      queryKey: ["excel-reports"],
      queryFn: () =>
        client("admin/reports/production/excel/", {
          responseType: "arraybuffer",
          params: {
            to_warehouse: warehouse,
            association,
            work_group: workGroup,
            from_day: fromDay,
            to_day: toDay,
            artisan: artisan,
          },
        }).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};

export { useGetReports, useDownloadReportsPdf, useDownloadReportsExcel };
